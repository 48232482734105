import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { GroundingDto } from "src/app/core/models/grounding-dto";
import { ToggleGroundingReturnedPreference } from "src/app/features/grounding-returned-selected/grounding-returned-selected.actions";
import { ToggleGroundingPreference } from "src/app/features/grounding-selected/grounding-selected.actions";

@Component({
  selector: "app-grounding-add-to-preference",
  templateUrl: "./grounding-add-to-preference.component.html",
  styleUrls: ["./grounding-add-to-preference.component.scss"],
})
export class GroundingAddToPreferenceComponent {
  @Input() item: any;
  @Input() design: "blue" | "white";
  vehicle: GroundingDto;

  constructor(
    private _store: Store<{}>,
    private _activatedRoute: ActivatedRoute
  ) {}

  get isDisabled(): boolean {
    const now = new Date().getTime();

    if (
      this._activatedRoute.snapshot.params.status &&
      this.item?.optionableGracePeriodEnd &&
      new Date(this.item?.optionableGracePeriodEnd).getTime() < now
    ) {
      return true;
    }

    return !(
      !this.vehicle?.getFirstOption ||
      [1, 2, 8].includes(this.vehicle?.getFirstOption?.status)
    );
  }

  get isActive(): boolean {
    if (this.isDisabled) {
      return true;
    }

    return !(
      !this.vehicle?.getFirstOption ||
      this.vehicle?.getFirstOption?.status === 8
    );
  }

  ngOnInit() {
    this.vehicle = new GroundingDto(this.item.fleetVehicle || this.item);
  }

  togglePreference() {
    if (this._activatedRoute.snapshot.params.status) {
      this._store.dispatch(
        ToggleGroundingReturnedPreference({
          id: this.item.id,
        })
      );
    } else {
      this._store.dispatch(
        ToggleGroundingPreference({
          id: this.item.id,
        })
      );
    }
  }
}
