<button
  *ngIf="item"
  [disabled]="isDisabled"
  (click)="togglePreference()"
  class="c-fav-button js-fav-button u-mr-xsmall@s"
>
  <span class="c-fav-button__icon" style="fill: transparent">
    <svg class="c-fav-button__svg" viewBox="0 0 24 24">
      <path
        [attr.fill]="
          isActive ? '#F5E850' : design === 'blue' ? '#ffffff' : '#006384'
        "
        [attr.stroke]="
          isActive ? '#F5E850' : design === 'blue' ? '#004666' : '#ffffff'
        "
        d="m22.5 9.6-7.2-1-3.3-6.6-3.2 6.6-7.3 1 5.3 5.2-1.3 7.2 6.5-3.4 6.5 3.4-1.2-7.2z"
      />
    </svg>
  </span>

  <span
    [ngClass]="{
      'c-fav-button__label  c-fav-button__label--on u-hide@m': true,
      'u-text-white': design === 'white',
      'u-text-brand': design === 'blue'
    }"
    *ngIf="!isDisabled && isActive"
  >
    {{ "actions.removePreEmption" | translate }}
  </span>

  <span
    [ngClass]="{
      'c-fav-button__label  c-fav-button__label--on u-hide@m': true,
      'u-text-white': design === 'white',
      'u-text-brand': design === 'blue'
    }"
    *ngIf="!isDisabled && !isActive"
  >
    {{ "actions.addToPreEmption" | translate }}
  </span>
</button>
