import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GroundingAddToPreferenceComponent } from "./grounding-add-to-preference.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [GroundingAddToPreferenceComponent],
  exports: [GroundingAddToPreferenceComponent],
  imports: [CommonModule, TranslateModule.forChild()],
})
export class GroundingAddToPreferenceModule {}
