/*
    All the regexp has been reviewed, and they're safe against a Denial of Service attack even because they're executed in the user's browser context
 */

export const vatValidation = /^[0-9]{11}$/;
export const fiscalCodePersonValidation = /[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
export const fiscalCodeCompanyValidation = /^[0-9]{11}$|^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
export const genericPhoneNumberValidation = /^\(?\d{3,4}\)?[-.]? ?\d{4,7}$/;
export const zipCodeValidation = /^[0-9]{5}$/;
export const internationalZipCodeValidation = /^[0-9]{2,12}$/;
export const provinceValidation = /^[a-zA-Z]{2}$/;
export const SDIvalidation = /^[0-9a-zA-Z]{5,7}$/;
export const publicAdministrationSDIvalidation = /^[0-9a-zA-Z]{5,6}$/;
export const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*+\-_?&])[A-Za-z\d@$!%+\-_*?&]{8,}$/;
export const ibanValidation = /^([A-Z]{2})(\d{2})([A-Z\d]+)$/;
export const filenameValidation = /^[a-z0-9_@()-]+$/;
export const dateValidation =   /^(\d{4})-(\d\d?)-(\d\d?)$/
